import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../i18n";

export default function Footer() {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <>
      {/* Top Footer Section */}
      <footer className="footer-section ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>{t("about_us_footer")}</h3>
                </div>
                <p>{t("about_us_description_footer")}</p>

                <ul className="footer-social">
                  {/* <li>
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.twitter.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://br.pinterest.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-pinterest"></i>
                    </a>
  </li> */}

                  <li>
                    <a
                      href="https://www.linkedin.com/company/eazydev/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>{t("important_link_footer")}</h3>
                </div>

                <ul className="footer-quick-links">
                  <li>
                    <Link to="/about-us">{t("about_us_link_footer")}</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">{t("contact_link_footer")}</Link>
                  </li>
                  {/* <li>
                    <Link href="/projects">Projects</Link>
                  </li> 
                  <li>
                    <Link to="/services">{t("services_link_footer")}</Link>
                  </li>
                  {/* <li>
                    <Link href="/team">Team</Link>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>{t("featured_services_footer")}</h3>
                </div>

                <ul className="footer-quick-links">
                  <li>
                    <Link to="/">{t("home_featured_services_footer")}</Link>
                  </li>
                  {/* <li>
                    <Link href="/blog">Blog</Link>
                  </li> */}
                  <li>
                    <Link to="/services">
                      {t("services_featured_services_footer")}
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="/partner">Client</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/contact-us">{t('contact_featured_services_footer')}</Link>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>{t("contact_info_footer")}</h3>
                </div>

                <div className="footer-info-contact">
                  <i className="flaticon-call-answer"></i>
                  <h3>{t("phone_contact_info_footer")}</h3>
                  <span>+55 (11) 97183-2670</span>
                </div>

                <div className="footer-info-contact">
                  <i className="flaticon-envelope"></i>
                  <h3>{t("email_contact_info_footer")}</h3>
                  <span>contato@eazydev.com.br</span>
                </div>

                <div className="footer-info-contact">
                  <i className="flaticon-maps-and-flags"></i>
                  <h3>{t("adress_contact_info_footer")}</h3>
                  <span>
                    Panamérica Park <br /> Av. Guido Caloi, 1000 - Jd. São Luís
                    - Bloco 5 - 4º Andar - São Paulo/SP
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>

        <div className="partner-shape-img1">
          <img
            src="/images/shape/partnar-shape-2.png"
            alt=""
            width={277}
            height={492}
          />
        </div>
      </footer>
      {/* End Top Footer Section */}

      {/* Bottom Footer Section */}
      <div className="copyright-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                &copy; {currentYear} {t("rights_reserved_footer")}
              </p>
            </div>

            {/* <div className="col-lg-6 col-md-6">
              <ul>
                <li>
                  <Link href="/terms-condition">Terms & Conditions</Link>
                </li>
                <li>
                  <Link href="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      {/* End Bottom Footer Section */}
    </>
  );
}
