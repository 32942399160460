import React from "react";
import Aos from "aos";
import "../../../node_modules/aos/dist/aos.css";

export default function AosAnimation() {

  React.useEffect(() => {
    Aos.init();
  }, []);
  
  return (
    <div>
    </div>
  );
}