import { Link } from "react-router-dom";

interface PageBannerProps {
  pageTitle: string;
  breadcrumbTextOne: string;
  breadcrumbTextTwo: string;
  breadcrumbUrl: string;
  bgImage: string;
}

export default function PageBanner({
  pageTitle,
  breadcrumbTextOne,
  breadcrumbTextTwo,
  breadcrumbUrl,
  bgImage,
}: PageBannerProps) {
  return (
    <>
      <div className="page-title-area" style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>{pageTitle}</h2>
                <ul>
                  <li>
                    <Link to={breadcrumbUrl}>
                      {breadcrumbTextOne}
                    </Link>
                  </li>
                  <li>{breadcrumbTextTwo}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}