import { useTranslation } from "react-i18next";
import "../../i18n";

export default function ContactInfo() {
  const { t } = useTranslation();

  return (
    <>
      <div className="contact-box pt-100 pb-70">
        <div className="container">
          <div className="row justify-content-center h-100">
            <div className="col-lg-4 col-md-6">
              <div className="single-contact-box">
                <i className="fa fa-map-marker"></i>
                <div className="content-title">
                  <h3>{t("adress_contato_info")}</h3>
                  <p>
                    Panamérica Park <br /> Av. Guido Caloi, 1000 - Jd. São Luís
                    - Bloco 5 - 4º Andar - São Paulo/SP
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-contact-box">
                <i className="fa fa-envelope"></i>
                <div className="content-title">
                  <h3>{t("email_contato_info")}</h3>
                  <p>contato@eazydev.com</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-contact-box">
                <i className="fa fa-phone"></i>
                <div className="content-title">
                  <h3>{t("phone_contato_info")}</h3>
                  <p>+55 (11) 97183-2670</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
