import { useTranslation } from 'react-i18next';
import '../../i18n';

export default function ServicesContent() {

  const { t } = useTranslation();

  const services = [
    {
      icon: "flaticon-big-data",
      title: t('title_devweb_our_services'),
      description: t('description_devweb_our_services')
    },
    {
      icon: "flaticon-data-analytics",
      title: t('title_design_our_services'),
      description: t('description_design_our_services')
    },
    {
      icon: "flaticon-document",
      title: t('title_devmvp_our_services'),
      description: t('description_devmvp_our_services')
    },
    {
      icon: "flaticon-chart",
      title: t('title_chatbot_our_services'),
      description: t('description_chatbot_our_services')
    },
    {
      icon: "flaticon-science",
      title: t('title_automation_our_services'),
      description: t('description_automation_our_services')
    },
    {
      icon: "flaticon-data-management",
      title: t('title_ecommerce_our_services'),
      description: t('description_ecommerce_our_services')
    }
  ];

  return (
    <>
      <div className="services-section pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span>{t('title_our_services')}</span>
            <h2>{t('discover_our_services')}</h2>
          </div>

          <div className="row">
            {services.map((service, index) => (
              <div key={index} className="col-lg-4 col-md-6">
                <div className="single-services-box">
                  <div className="icon">
                    <i className={service.icon}></i>
                  </div>
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
    </>
  )
}