import Customers from "../../components/Common/Customers";
import Testimonial from "../../components/Common/Testimonial";
import MainBanner from "../../components/Home/MainBanner";
import OurServices from "../../components/Home/OurServices";
import Footer from "../../components/Layouts/Footer";
import Navbar from "../../components/Layouts/Navbar";

export default function Home() {
    return (
        <>
            <Navbar />

            <MainBanner />

            <OurServices />

            <Testimonial />

            <Customers />

            <Footer />
        </>
    )
}