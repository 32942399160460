import { useTranslation } from 'react-i18next';
import '../../i18n';
import Navbar from '../../components/Layouts/Navbar';
import PageBanner from '../../components/Common/PageBanner';
import Footer from '../../components/Layouts/Footer';
import PropostaContent from '../../components/Proposta/PropostaContent';
import Infraestrutura from '../../components/Proposta/Infraestrutura';

export default function Proposta() {

  const {t} = useTranslation();

  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle={t('pageTitle_proposta')}
        breadcrumbTextOne={t('breadcrumbTextOne_proposta')}
        breadcrumbTextTwo={t('breadcrumbTextTwo_proposta')}
        breadcrumbUrl="/"
        bgImage="/images/page-title-bg-2.jpg"
      />

      <PropostaContent />

      <Infraestrutura />
    
      <Footer />
    </>
  );

}