import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTranslation } from "react-i18next";
import "../../i18n";

export default function Infraestrutura() {

  const { t } = useTranslation();

  return (
    <>
      <div className="services-section" style={{ background: '#f2f2f2', padding: '50px 0 50px 0' }}>
        <div className="container">
          <div className="section-title">
            <h2>{t("title_infraestrutura")}</h2>
            <span>{t("phrase_infraestrutura")}</span>
            <h3 className='mt-5'>{t("team_infraestrutura")}</h3>
            <div className="row mt-4">
              <div className="col-md-4">
                <Card>
                  <CardMedia
                    image="/static/images/cards/contemplative-reptile.jpg"
                    title="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {t("employee1_name_infraestrutura")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t("employee1_office_infraestrutura")}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-4">
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {t("employee2_name_infraestrutura")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t("employee2_office_infraestrutura")}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-4">
                <Card>
                  <CardMedia
                    image="/static/images/cards/contemplative-reptile.jpg"
                    title="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {t("employee3_name_infraestrutura")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t("employee3_office_infraestrutura")}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>

            <h3 className='mt-5'>{t("project_cost_phase1")}</h3>

            <div className="row mt-4">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <Card variant="outlined">
                  <Box sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography gutterBottom variant="h5" component="div">
                        {t("development_hours_infraestrutura")}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {t("hours_infraestrutura")}
                      </Typography>
                    </Stack>
                  </Box>
                </Card>
              </div>
              <div className="col-md-3"></div>
            </div>

            {/* <div className="row mt-4">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <Card variant="outlined">
                  <Box sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography gutterBottom variant="h5" component="div">
                        {t("total_investiment_infraestrutura")}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div" style={{
                        color: 'red', textDecoration: 'line-through',
                        fontSize: '15px'
                      }}>
                        {t("cost_total_investiment_infraestrutura")}
                      </Typography>
                    </Stack>
                  </Box>
                </Card>
              </div>
              <div className="col-md-3"></div>
            </div> */}

            <div className="row mt-4">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <Card variant="outlined">
                  <Box sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography gutterBottom variant="h5" component="div">
                        {t("investment_partnership_infraestrutura")}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div" style={{
                        color: 'green',
                        fontSize: '20px',
                        fontWeight: 'bold'
                      }}>
                        {t("cost_investment_partnership_infraestrutura")} / <span style={{ color: '#00b0ee', fontSize: '12px' }}>{t("monthly_maintenance_infraestrutura")}</span>
                      </Typography>
                    </Stack>
                  </Box>
                </Card>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row mt-4">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <Card variant="outlined">
                  <Box sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography gutterBottom variant="h5" component="div">
                        {t("investment_partnership_infraestrutura_after_6montlhys")}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div" style={{
                        color: 'green',
                        fontSize: '20px',
                        fontWeight: 'bold'
                      }}>
                        {t("cost_investment_partnership_infraestrutura_after_6montlhys")} / <span style={{ color: '#00b0ee', fontSize: '12px' }}>{t("cost_maintenance_infraestrutura")}</span>
                      </Typography>
                    </Stack>
                  </Box>
                </Card>
              </div>
              <div className="col-md-3"></div>
            </div>

            {/* <h3 className='mt-5'>{t("service_costs_after_implementation_infraestrutura")} </h3> */}

            {/* <div className="row mt-4">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <Card variant="outlined">
                  <Box sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography gutterBottom variant="h5" component="div">
                        {t("service_server_infraestrutura")}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {t("cost_server_infraestrutura")} / <span style={{ color: '#00b0ee', fontSize: '12px' }}>{t("monthly_server_infraestrutura")}</span>
                      </Typography>
                    </Stack>
                  </Box>
                </Card>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row mt-4">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <Card variant="outlined">
                  <Box sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography gutterBottom variant="h5" component="div">
                        {t("service_maintenance_infraestrutura")}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {t("cost_maintenance_infraestrutura")} / <span style={{ color: '#00b0ee', fontSize: '12px' }}>{t("monthly_maintenance_infraestrutura")}</span>
                      </Typography>
                    </Stack>
                  </Box>
                </Card>
              </div>
              <div className="col-md-3"></div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}