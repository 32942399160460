/* eslint-disable jsx-a11y/img-redundant-alt */
import { useTranslation } from 'react-i18next';
import '../../i18n';

export default function FeaturedService() {

  const { t } = useTranslation();

  const featuredServices = [
    t('devweb_featured_service'),
    t('design_featured_service'),
    t('devmvp_featured_service'),
    t('chatbot_featured_service'),
    t('automation_featured_service'),
    t('ecommerce_featured_service')
  ];

  return (
    <>
      <div className="software-section pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="software-content">
                <span>{t('title_featured_service')}</span>
                <h2>{t('phrase_featured_service')}</h2>
                <p>
                  {t('description_featured_service')}
                </p>
                
                <ul className="features-list">
                  {featuredServices.map((service, index) => (
                    <li key={index}>
                      <span>{service}</span>
                    </li>
                  ))};
                </ul>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="software-image">
                <img
                  src="/images/software.png"
                  alt="image"
                  width={555}
                  height={356}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}