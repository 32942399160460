import { useTranslation } from 'react-i18next';
import '../../i18n';

export default function AboutUsContent() {

  const { t } = useTranslation();

  const aboutItems = [
    t('item1_about_us'),
    t('item2_about_us'),
    t('item3_about_us'),
    t('item4_about_us'),
  ]

  return (
    <>
      <div className="about-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-image">
                <img
                  src="/images/about.png"
                  alt=""
                  width={490}
                  height={526}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-content">
                <span>{t('title_about_us')}</span>
                <h2>{t('phrase_about_us')}</h2>
                <p>
                  {t('description_about_us')}
                </p>

                <ul className="about-list">
                  {aboutItems.map((item, index) => (
                    <li key={index}>
                      <i className="flaticon-tick" />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}